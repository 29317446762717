
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING MVP
    baseUrl = 'https://api.roca.bigcityvoucher.co.in/v1'; 
  
  //prod

   //baseUrl ='https://hubadminapi.redemptionportalrbppl.in/v1'



  login = this.baseUrl + "/hubadmin/log-in";
  logout = this.baseUrl + "/hubadmin/logout";

  //company
  getCompanylist = this.baseUrl + "/hubadmin/list-company";
  addCompany = this.baseUrl + "/hubadmin/add-company";
  editCompany = this.baseUrl + "/hubadmin/edit-company";
  deleteCompany = this.baseUrl + "/hubadmin/deactivate-company";
  generateId = this.baseUrl + "/hubadmin/generate-id";

  orderDetails = this.baseUrl + '/client/getordercumulative'


  //programns

  addPrograms = this.baseUrl + "/hubadmin/add-programs";
  editPrograms = this.baseUrl + "/hubadmin/edit-programs";
  listPrograms = this.baseUrl + "/hubadmin/list-programs"
  deactivateProgram = this.baseUrl + "/hubadmin/deactivate-programs"

  // Product Category

  addCategory = this.baseUrl + "/hubadmin/add-product-cats"
  editCategory = this.baseUrl + "/hubadmin/edit-product-cats"
  listCategory = this.baseUrl + "/hubadmin/list-product-cats"
  deactivateCategory = this.baseUrl + "/hubadmin/deactivate-product-cats"

  //variation-masters 
  addVariationMas = this.baseUrl + "/hubadmin/add-variation-mas"
  listVariationMas = this.baseUrl + "/hubadmin/list-variation-mas"

  // variation
  addVariation = this.baseUrl + "/hubadmin/add-variation"
  listVariation = this.baseUrl + "/hubadmin/list-variation"

  // Products
  uploadProductFile = this.baseUrl + "/hubadmin/product-excel-upload"

  addProducts = this.baseUrl+"/hubadmin/add-product"

  // /Products-variation
  uploadProductVarFile = this.baseUrl + "/hubadmin/product-variation-upload"

  // ListOFProducts
  ListOfProducts = this.baseUrl + "/hubadmin/list-product"
  editProducts = this.baseUrl+"/hubadmin/edit-product"
  deleteDenomination = this.baseUrl+"/hubadmin/disable-denomination"

  //delivery partner
  getdelPartnerList = this.baseUrl + "/delivery-partner/get-partner?";

  //Redemption Panel
  getOrderList = this.baseUrl + "/redemption/order-listing?";
  getListProgram = this.baseUrl + "/hubadmin/list-programs";
  uploadEGVOrderStatus = this.baseUrl + "/redemption/order-offer-code-upload";
  uploadPhysiaclOrderStatus = this.baseUrl + "/redemption/order-statusupload";
  rejectDropdown = this.baseUrl + "/redemption/rejection-reason"; // rejected reason
  approveRejectOrder = this.baseUrl + "/redemption/approve-rejectby-bcp";
  deliveryPartner = this.baseUrl + "/redemption/delivery-partner";



  changeOrderStatusByForm = this.baseUrl + "/ops-panel/bulk-status-update?program_id=1";
  changeOrderStatusByeExcel = this.baseUrl + "/ops-panel/order-statusupload?program_id=1";
  getDownOrderExlData = this.baseUrl + "/ops-panel/get-orderexcel";
  getCancelReasonDropDown = this.baseUrl + "/ops-panel/get-order-cancelling-reason";

  getVoucherCodes = this.baseUrl + "/redemption/offercode"

  // Reverse Points
  ReversePoints = this.baseUrl + "/redemption/reversal-panel";
  RedemptionReversePoint = this.baseUrl + "/redemption/reverse-point"
  // physical reward
  downloadEgvRewards = this.baseUrl + "/redemption/egv-summary"
  downloadPhysicalRewardExcel = this.baseUrl + "/redemption/order-summary";
  orderStatus = this.baseUrl + "/redemption/readorderstatus";
  deliveryPatner = this.baseUrl + "/redemption/delivery-partner";
  storeStatus = this.baseUrl + "/redemption/setorderstatus";

  //Finance
  getProformaInvoice = this.baseUrl + "/client/getperforma?";
  approveSingleInvoice = this.baseUrl + "/client/setapprovalstatus?new_approval_status=2";
  downloadSingleInvoice = this.baseUrl + "/finance/proforma-download?";
  uploadInvoiceStatus = this.baseUrl + "/hubadmin/upload-invoice";
  getInvoiceList = this.baseUrl + "/hubadmin/list-invoice?";
  getServiceInvoiceList = this.baseUrl + "/finance/list-service-profoma?service_pi_status=2&invoice_flag=1"
  generatePI = this.baseUrl + "/finance/generate-proforma";
  verifyInvoice = this.baseUrl + "/hubadmin/verify-invoice?invoice_id=";
  updateInvoiceStatus = this.baseUrl + "/finance/update-invoice-status"
  downloadProofZip = this.baseUrl + "/client/download-invoice-proofs?invoice_id=";
  deliverOrders = this.baseUrl+'/finance/delivered-orders'
  servicesPI = this.baseUrl +'/finance/service-profoma'
  getServicePI = this.baseUrl + "/finance/list-service-profoma?service_pi_status=2&invoice_flag="
  uploadPayment = this.baseUrl + "/finance/upload-service-payment"
  uploadInvoiceService = this.baseUrl + "/finance/upload-invoice-service"
  //Agent Panel
  getAgentOrderList = this.baseUrl + "/report/customer-orders?";
  getSingleRedemptionInfo = this.baseUrl + "/redemption/offercode"
  getAgentOldOrdersList=this.baseUrl+"/report/old-redemptions"

  // Balance Check

  getAmozonWoohoBalance =this.baseUrl + '/common/check-balance?&secret_key=$2y$10$z0ihWtyW2HsEAh1VMNbGUOXKN1w9RJo8Vgwb6PLCsQLU.F2OcVU5W' 
}
export interface ApiConfigurationInterface { }